import seedrandom from 'seedrandom';

class FreeCell {
    constructor(props, cells, score, msg) {
        const rnd = seedrandom(props.seed);
        this.props = props;
        if (!cells) {
            cells = [];
            for(var i=0; i<this.props.ymax; i++) {
              var row = [];
              for(var j=0; j<this.props.xmax; j++) {
                row.push(Math.floor(rnd() * this.props.cmax)+1);
              }
              cells.push(row);
            }
        }
        this.cells = cells;
        this.score = score || 0;
        this.over = false;
        this.msg = msg || ["Enjoy!"];
    }

    deepCopy() {
        return this.cells.map((row) => {
            return row.map((cell) => { return cell })
        })
    }

    async process(x, y, delay, callback) {
      const game = this.select(x, y);
      let res = 0;
      if (game) {
        res = res + 1;
        this.move = {x:x, y:y};
        callback(game, true);
        await this.timeout(delay);
        const game1 = game.processY();
        if (game1) {
          res = res + 1;
          callback(game1, false);
          await this.timeout(delay);
        }
        const game2 = (game1 || game).processX();
        if (game2) {
          callback(game2, false);
        }
      }
      return res;
    }
    timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
  
    checkEnd() {
        var count = 0;
        for (var y=0; y<this.props.ymax; y++) {
            for (var x=0; x<this.props.xmax; x++) {
                if (this.select(x,y)) {
                    return null;
                }
                if (this.cells[y][x] > 0) {
                    count++;
                }
            }
        }
        var bonus = 50;
        var msg = ["Great Job! (Bonus: " + bonus + ")"];
        if (count > 0) {
            bonus = -(count * (count+1));
            msg = ["Game Over (Penalty: " + bonus + ")"];
        }
        var game = new FreeCell(this.props, this.deepCopy(), this.score+bonus, msg);
        game.over = true;
        return game;
    }
        
    select(x, y) {
        if (this.cells[y][x] === 0) return null;
        var cells = this.deepCopy();
        const props = this.props;
        function inner(x,y, val) {
            if (x<0 || x>=props.xmax || y<0 || y>=props.ymax) return 0;
            if (cells[y][x] !== val) return 0;
            cells[y][x] = 0;
            return 1  
                + inner(x-1, y, val)
                + inner(x+1, y, val)
                + inner(x, y-1, val)
                + inner(x, y+1, val)
        }
        const count = inner(x, y, cells[y][x]);
        if (count < 2) return null;
        const earned = count * (count-1) / 2;
        const msg = ["Earned: " + earned];
        return new FreeCell(this.props, cells, this.score+earned, msg);
    }

    processY() {
        var cells = this.deepCopy();
        var count = 0;
        for (let x=0; x<this.props.xmax; x++) {
            for (let y=1; y<this.props.ymax; y++) {
                if (cells[y][x] !== 0) {
                    var d = 0;
                    while (y-d-1 >= 0 && cells[y-d-1][x] === 0) {
                        d++;
                    }
                    if (d>0) {
                        cells[y-d][x] = cells[y][x];
                        cells[y][x]=0;
                        count++;
                    }
                }
            }
        }
        if (count===0) return null;
        return new FreeCell(this.props, cells, this.score, this.msg);
    }

    processX() {
        var cells = this.deepCopy();
        for (let x=this.props.xmax-2; x>=0; x--) {
            const sum = cells.reduce((sum,row) => {
                return sum + row[x];
            }, 0);
            if (sum === 0) {
              for (let x1=x; x1<this.props.xmax-1; x1++) {
                for (let y=0; y<this.props.ymax; y++) {
                    cells[y][x1] = cells[y][x1+1];
                }
              } 
              for (let y=0; y<this.props.ymax; y++) {
                cells[y][this.props.xmax-1] = 0;
              }
            }         
        }
        return new FreeCell(this.props, cells, this.score, this.msg);
    }

    verify(score, moves) {
        var game = this;
        moves.forEach((move) => {
            console.log("x,y", move.x, move.y);
            game = game.select(move.x, move.y);
            if (game) {
                game = game.processY() || game;
                game = game.processX() || game;
            } else {
                return false;
            }
        });
        game = game.checkEnd();
        return game && game.score === score;
    }
}

export default FreeCell;
