
export const migrateScoreTimestamps = async (db, isAdmin) => {
    const querySnapshot = await db.collection("freecells").get();
    const snapshots = [];
    querySnapshot.forEach((snapshot) => {
        snapshots.push(snapshot);
    });
    const promises = snapshots.map(async (snapshot) => {
        await migrateScores(snapshot.ref, isAdmin);
    });
    await Promise.all(promises);
};
const migrateScores = async (ref, isAdmin) => {
    const querySnapshot = await ref.collection("scores").get();
    const snapshots = [];
    querySnapshot.forEach((snapshot) => {
        snapshots.push(snapshot);
    });
    const promises = snapshots.map(async (snapshot) => {
        let doc = snapshot.data();
        if (doc && typeof doc.timestamp === "string") {
            console.log(doc ? doc.timestamp : "N/A");
            if (isAdmin) {
                await snapshot.ref.set({ timestamp: new Date(doc.timestamp) }, { merge: true });
            }
        }
    });
    await Promise.all(promises);
};
