import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Header from './Header';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    margin: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
});

const About = props => {
  const { classes, user } = props;
  return (
    <React.Fragment>
      <Header user={user} login="/Login/target/about" />
      <div className={classes.root}>
        <Paper className={classes.paper}>
            <Typography>
              This web site was created by @snakajima, 
              using Firebase, React.js and Material-UI.</Typography>
        </Paper>
      </div>
    </React.Fragment>
  );
}

About.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(About);
