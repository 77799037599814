import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
  },
  label: {
    color: "grey",
    fontSize: "calc(2.5vmin)",
    textAlign: "center",
  },
  body: {
    width: "100%",
    fontSize: "calc(5vmin)",
    textAlign: "center",
  }
});

class LabeledText extends React.Component {
  render() {
    const { classes, label } = this.props;
    return <div className={classes.root}>
      <div className={classes.label}>{label}</div>
      <div className={classes.body}>
      { this.props.children }
      </div>
    </div>
  }
}

LabeledText.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LabeledText);