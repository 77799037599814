import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GameItem from './GameItem';
import { Typography } from '@material-ui/core';
import Processing from './Processing';

//import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    margin: theme.spacing.unit * 2,
  },
  paper: {
    marginBottom: theme.spacing.unit,
    padding: theme.spacing.unit,
  },
  emojis: {
    marginRight: theme.spacing.unit * 2,
  },
  name: {
  },
  cell: {
    margin: "1px 1px 1px 1px",
    width: "calc(5.0vmin)",
    height: "calc(5.0vmin)",
    float: "left",
    borderRadius: "calc(1vmin)",
    fontSize: "calc(4.3vmin)",
    textAlign: "center",
    boxShadow: "2px 2px 2px grey",
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

class GameList extends React.Component {
  state = { newGames:null, myGames:[] };

  componentDidMount() {
    const { db } = this.props;
    const queryNew = db.collection("freecells").orderBy("timestamp", "desc").limit(10);
    this.detacherNew = queryNew.onSnapshot((snapshot)=>{
        var games = [];
        snapshot.forEach((doc)=>{
            var game = doc.data();
            game.gameId = doc.id;
            games.push(game);
        }); 
        this.setState({newGames:games});
    });
}

  componentWillUnmount() {
    this.detacherNew();
    if (this.detacherMine) { this.detacherMine() }
  }

  render() {
    const { classes, user, db } = this.props;
    if (this.state.newGames===null) {
      return <Processing user={user} />
    }

    if (user && !this.detacherMine) {
      const queryMine = db.collection("freecells").where("uid", "==", user.uid).orderBy("timestamp", "desc").limit(10);
      this.detacherMine = queryMine.onSnapshot((snapshot)=>{
        var games = [];
        snapshot.forEach((doc)=>{
            var game = doc.data();
            game.gameId = doc.id;
            games.push(game);
        }); 
        this.setState({myGames:games});
      });
    }

    return (
      <React.Fragment>
        <Header user={user} login="/Login/target/list" />
        <Grid className={classes.root}>
          <Typography variant="h6">New Games</Typography>
          {
            this.state.newGames.map((game)=>{
              return <GameItem key={game.gameId} user={user} game={game} db={ db } />
            })
          }
        </Grid>
        { (user && this.state.myGames.length > 0) ? 
        <Grid className={classes.root}>
          <Typography variant="h6">Your Games</Typography>
          {
            this.state.myGames.map((game)=>{
              return <GameItem key={game.gameId} user={user} game={game} db={ db } />
            })
          }
        </Grid>
        : ""}
      </React.Fragment>
    ) 
  }
}

GameList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GameList);