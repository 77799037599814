import React from 'react';

class Tweet extends React.Component {
    componentDidMount() {
        if (window.twttr) {
            window.twttr.widgets.load();
        }
    }
    
    componentDidUpdate() {
        if (window.twttr) {
            // BUGBUG: For some reason, this does not work
            window.twttr.widgets.load();
        }
    }
    render() {
        const { hashtags, text, via } = this.props;
        const url = this.props.url || window.location.href;
        var intent = "url=" + encodeURIComponent(url);
        if (hashtags) {
            intent += "&hashtags=" + hashtags;
        }
        if (text) {
            intent += "&text=" + encodeURIComponent(text);
        }
        if (via) {
            intent += "&via=" + via;
        }
        console.log(text);
        return (
            <span>
                <a href={"https://twitter.com/intent/tweet?" + intent} 
                    className="twitter-hashtag-button" data-size="large">Tweet</a>
            </span>
        )
    }
}

export default Tweet;