import React from 'react';
import Header from '../Header';
import { migrateScoreTimestamps } from '../generated/migrate';

class FuncTest extends React.Component {
    async componentDidMount() {
        const { db } = this.props;
        await migrateScoreTimestamps(db, false);
    }
    render() {
        const { user } = this.props;
        return (
            <React.Fragment>
                <Header user={user} />
                <div style={{display:"flex", justifyContent:"center"}}>
                    <h1>Function Test</h1>
                </div>
            </React.Fragment>
        )
    }
}

export default FuncTest;