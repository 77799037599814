import React, { Fragment }  from 'react';
import FreeCell from './FreeCell';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import UndoIcon from '@material-ui/icons/Undo';
import RewindIcon from '@material-ui/icons/FastRewind';
import { Redirect } from 'react-router-dom';
import TweetBrag from './TweetBrag';
import LabeledText from './LabeledText';

const DELAY = 100;

const styles = theme => ({
  textBox: {
    width: "calc(20vmin)",
  },
  highscore: {
    color: theme.palette.primary.main    
  },
  worldrecord: {
    color: theme.palette.secondary.main    
  },
  text: {
    fontSize: "calc(4vmin)"
  },
  button: {
    margin: theme.spacing.unit,
  },
  icon: {
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  cell0: {
    opacity: 0,
  },
  cell1: {
    background: "#ffffff",
  },
  cell2: {
    background: "#ffd8d8",
  },
  cell3: {
    background: "#d8d8ff",
  },
  cell: {
    margin: "1px 1px 1px 1px",
    width: "calc(8.0vmin)",
    height: "calc(8.0vmin)",
    float: "left",
    borderRadius: "calc(1vmin)",
    fontSize: "calc(7vmin)",
    textAlign: "center",
    boxShadow: "2px 2px 2px grey",
  },
  cellV: {
    margin: "1px 1px 1px 1px",
    width: "calc(12.0vmin)",
    height: "calc(12.0vmin)",
    float: "left",
    borderRadius: "calc(1vmin)",
    fontSize: "calc(11vmin)",
    textAlign: "center",
    boxShadow: "2px 2px 2px grey",
  },
  popup: { 
    marginLeft: "calc(5.0vmin)",
    marginTop: "calc(5.0vmin)",
    padding: "calc(1vmin)",
    borderRadius: "calc(0.5vmin)",
    background: "white",
    boxShadow: "2px 2px 2px grey",
  },
  message: {
    fontSize: "calc(4vmin)",
    color: theme.palette.secondary.main,
  },
});

class Game extends React.Component {
  constructor() {
    super();
    this.state = {game:null, highscore:0, moves:[], redirect:null};
    this.stack = [];
    this.popup = false;
    this.retry = this.retry.bind(this);
    this.undo = this.undo.bind(this);
    this.login = this.login.bind(this);
  }
  componentDidMount() {
    const game = new FreeCell(this.props.gameInfo);
    console.log("componentDidMount", game);
    this.setState({gameInfo:this.props.gameInfo, game:game});
  }

  async handleClick(e, x, y) {
    e.preventDefault();
    const res = await this.state.game.process(x, y, DELAY, (game, fPush)=>{
      if (fPush) {
        this.stack.push(this.state.game);
        this.setState({popup:true});
        setTimeout(()=>{this.setState({popup:false})}, 500);
      }
      this.setState({game:game});
    });    

    if (res > 0) {
      const game = this.state.game.checkEnd();
      if (game) {
        this.setState({game:game});
        const moves = this.stack.map((game)=>{
          return game.move;
        });
        if (true) {
          // Test code for verification, which runs on the server side
          const gameInfo = this.state.gameInfo;
          const gameX = new FreeCell(gameInfo);
          console.log("verified:", gameX.verify(game.score, moves))
        }
        this.setState({moves:moves});
        if (game.score > this.props.highscore && this.props.user) {
          console.log("moves", moves);
          const refHighscore = this.props.db.collection("users").doc(this.props.user.uid)
                                  .collection("freecells").doc(this.props.gameId);
          refHighscore.set({
            score:game.score,
            name:this.props.user.displayName,
            moves:moves
          }, { merge: true }).then(()=> {
            if (this.props.scoreDidUpdate) {
              this.props.scoreDidUpdate();
            }
          });
        }
      }
    }

  }

  retry(e) {
    e.preventDefault();
    const game = new FreeCell(this.state.game.props);
    this.setState({game:game, moves:[]});
    this.stack = [];
  }

  // We come here when the user clicks the login button in the game-over popup.
  login(e) {
    e.preventDefault();
    const info = {cmd:"hiscore", score:this.state.game.score, moves:this.state.moves, 
                  gameId:this.props.gameId, url:"/play/"+this.props.gameId};
    const encoded = encodeURIComponent(JSON.stringify(info));
    this.setState({redirect:"/login/cmd/"+encoded});
  }

  undo(e) {
    e.preventDefault();
    if (this.stack.length > 0) {
      const game = this.stack.pop();
      this.setState({game:game});
    }
  }

  reset(e, score) {
    console.log("reset called", this.props);
    e.preventDefault();
    if (this.props.user) {
      const refHighscore = this.props.db.collection("users").doc(this.props.user.uid)
      .collection("freecells").doc(this.props.gameId);
      refHighscore.set({
        score:score,
        name:this.props.user.displayName,
        moves:[{x:0, y:0}]
      }, { merge: true }).then(()=> {
        if (this.props.scoreDidUpdate) {
          this.props.scoreDidUpdate();
        }
      });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    if (!this.state.game) {
      return "";
    }
    const { classes, user, highscore, gameId, tweet, scores } = this.props;
    console.log("render", this.state.game);
    const emojis = this.state.game.props.emojis;
    const cellClass = this.props.landscape ? classes.cell : classes.cellV;
    return (
        <div>
          <Grid container spacing={8}>
            <Grid item className={classes.textBox}>
            <LabeledText label="Score">{this.state.game.score}</LabeledText>
            </Grid>
            { highscore ? 
            <Grid item className={classes.textBox}>
            <LabeledText label="My HiScore" >
              <span className={classes.highscore}>{highscore}</span></LabeledText>
            </Grid>
          : ""}
          { (scores && scores.length>0) ?
            <Grid item className={classes.textBox}>
            <LabeledText label="WR" >
            <span className={classes.worldrecord}>{scores[0].score}</span></LabeledText>
            </Grid>
            : ""}
          </Grid>
          <div style={{userSelect: "none", background:"#eeeeee"}}>
            { (this.state.popup || this.state.game.over) ? (
            <div style={{position:"absolute", zIndex:1000}}>
              <div className={classes.popup}>{
                this.state.game.msg.map((text,index)=>{
                  return <div key={index} className={classes.message}>{text}</div>
                })
                }
                {
                  this.state.game.over ? (<Fragment>
                    <div style={{marginTop:"calc(2vmin)"}}>
                    <Button variant="contained" color="primary" onClick={this.retry}>
                    Retry
                    </Button>
                    { tweet ? <Fragment>
                    <div style={{float:"right"}}>
                        <TweetBrag user={user} highscore={highscore} />
                    </div>
                    <div style={{clear:"both"}} />
                    </Fragment>
                    : "" }
                    </div>
                    { (user || !gameId) ? "" :
                    <div style={{marginTop:"calc(2vmin)"}}>
                    <Button variant="contained" color="secondary" onClick={this.login}>
                    Login
                    </Button> and post your score.
                    </div>
                   }
                </Fragment>) : ""
                }
              </div>
            </div>) : "" }
            {this.state.game.cells.map((_val, y) => {
              var rows = this.state.game.cells[this.state.gameInfo.ymax-1-y];
              var row = rows.map((val, x) => {
                return <div className={cellClass + " " + classes["cell"+val]} key={x} 
                            onClick={(e)=>this.handleClick(e, x, this.state.gameInfo.ymax-1-y)}>
                              <span role="img" aria-label="emoji">{val>0 ? emojis[val-1]:""}</span>
                            </div>
              });
              return <div style={{display:"flex"}} key={y}>{row}</div>
            })}
          </div>
        <div>
        <Fab onClick={this.retry} variant="extended" size="medium" disabled={this.stack.length === 0} className={classes.button}>
          <RewindIcon className={classes.icon} />
        </Fab>   
        <Fab onClick={this.undo} variant="extended" size="medium" disabled={this.stack.length === 0} className={classes.button}>
          <UndoIcon className={classes.icon} />
        </Fab>
        { (user && user.uid==="KEEWzNjMcnUk7OdHndNwdSpqkZm2") ?
        <div>
        <Button onClick={(e)=>{this.reset(e, 0)}} variant="contained" color="secondary" className={classes.button}>
          Reset
        </Button>       
        <Button onClick={(e)=>{this.reset(e, 999)}} variant="contained" color="secondary" className={classes.button}>
          Hack
        </Button>
        </div>       
        : "" }
        </div>
      </div> 
    );
  }
}

Game.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Game);
