import React from 'react';
import Header from '../Header';
import LabeledText from '../LabeledText';
import Grid from '@material-ui/core/Grid';

class FlexTest extends React.Component {
    render() {
        const { user } = this.props;
        return (
            <React.Fragment>
                <Header user={user} />
                <Grid container spacing={8}>
                  <Grid item style={{width:"calc(15vmin)"}}>
                    <LabeledText label="Score">333</LabeledText>
                  </Grid>
                  <Grid item style={{width:"calc(15vmin)"}}>
                    <LabeledText label="My Record">444</LabeledText>
                  </Grid>
                  <Grid item style={{width:"calc(15vmin)"}}>
                    <LabeledText label="W. Record">555</LabeledText>
                  </Grid>
                </Grid>
                <div style={{display:"flex", justifyContent:"center"}}>
                  <div>
                  <div style={{ width: "300px", height:"300px", background:"yellow", float:"left" }}>A</div>
                    <div style={{ width: "300px", height:"300px", background:"blue", float:"left" }}>A</div>
                    <div style={{ width: "300px", height:"300px", background:"red", float:"left" }}>A</div>
                  </div>
                </div>
            </React.Fragment>
        )
    }
}

export default FlexTest;