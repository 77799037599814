import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import TweetBrag from './TweetBrag';

const styles = theme => ({
    paper: {
        marginLeft: theme.spacing.unit * 0.5,
        padding: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        fontSize: "calc(2.5vmin)",
        width: "calc(33vmin)",
      },
    heading: {
        fontWeight: "bold",
        marginTop: "0.5em",
        marginBottom: "0.2em",
    },
    entry: {
        textAlign: "left",
        width: "100%",
        whiteWpace: "nowrap", 
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    myEntry: {
        textAlign: "left",
        width: "100%",
        whiteWpace: "nowrap", 
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: theme.palette.primary.main,
    },
    rank: {
        display: "none",
    },
    score: {
        marginRight: "4px",
    },
    makeBox: {
        textAlign: "center",
        paddingTop: theme.spacing.unit,
    }
});

class Ranking extends React.Component {
    state = {params:{}};

    componentDidMount() {
        const refGame = this.props.db.collection("freecells").doc(this.props.gameId);
        refGame.get().then((snapshot)=>{
            //console.log("gameParams", snapshot.data());
            this.setState({params:snapshot.data()});
        });
    }

    render() {
        const { classes, user, landscape, tweet, highscore, scores } = this.props;
        if (!scores) {
            return <Fragment/>
        }
        return <Fragment>
            <Paper className={classes.paper} elevation={1} style={ landscape ? {} : {width:"100%", fontSize: "calc(5vmin)"} }>
            <div className={classes.heading}>
                Ranking
                <div style={{float:"right"}}>
                { tweet ?
                <TweetBrag user={user} highscore={highscore} /> : ""
                }
                </div>
                <div style={{clear:"both"}} />
            </div>
            <div></div>
            {
                scores.map((score, index)=>{
                    return <div key={index} className={(user && score.uid===user.uid) ? classes.myEntry:classes.entry}>
                        <span className={classes.rank}>{index+1}.</span>
                        <span className={classes.score}> {score.score}</span>
                        <span>{score.name}</span>
                    </div>
                })
            }
            <div className={classes.heading}>
                Created by
            </div>
            <div>{this.state.params.name || ""}</div>
            <div className={classes.heading}>
                Make Your Own Game
            </div>
            <div className={classes.makeBox}>
                <Button variant="contained" color="primary" component={Link} to="/make">
                Make
                </Button>
            </div>
        </Paper>
        </Fragment>
    }
}

Ranking.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Ranking);
  