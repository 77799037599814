import React from 'react';
import Game from './Game'
import Header from './Header';
import Ranking from './Ranking';
import Processing from './Processing';

class Play extends React.Component {
  state = { highscore:0, tweet:true, scores:[] };
  componentDidMount() {
    const { match:{params:{gameId}}} = this.props;
    const refGame = this.props.db.collection("freecells").doc(gameId);

    refGame.get().then((doc)=>{
      const gameInfo = doc.data();
      if (gameInfo) {
        this.setState({gameInfo:gameInfo});
      }
    });

    const refScores = refGame.collection("scores");
    const query = refScores.orderBy("score", "desc").orderBy("timestamp").limit(10);
    this.detacherRanking = query.onSnapshot((snapshot)=>{
        var scores = [];
        snapshot.forEach((doc)=>{
            var score = doc.data();
            score.uid = doc.id;
            scores.push(score);
        });
        console.log("scores:", scores)
        this.setState({scores:scores});
    });
}
  componentWillUnmount() {
    this.detacherRanking();
    if (this.detacher) {
        this.detacher();
    }
  }

  scoreDidUpdate() {
    // HACK ALERT: This code is written to work around the update issue of Twitter component.
    this.setState({tweet:false});
    setTimeout(()=>{this.setState({tweet:true})}, 10);
  }

  render() {
    const { db, user, params:{landscape}, embed, match:{params:{gameId}} } = this.props;

    if (!this.state.gameInfo) {
      return <Processing user={user}/>
    }

    // This is necessary to handle login/logout transition
    if (user && !this.detacher) {
      const refHighscore = db.collection("users").doc(user.uid)
                                      .collection("freecells").doc(gameId);
      this.detacher = refHighscore.onSnapshot((doc)=>{
        const data = doc.data();
        if (data) {
          this.setState({highscore:data.score});
          this.scoreDidUpdate();
        }
      });
    } else if (!user && this.detacher) {
      this.detacher();
      this.detacher = null;
    }
    //console.log(this.state.width, this.state.height)
    const stylesLandscape = {
      game: {
        float:"left",
      },
      ranking: {
        float:"left",
      }
    };
    const stylesPortrait = {
      game: {
      },
      ranking: {
      }
    };
    const styles = landscape ? stylesLandscape : stylesPortrait;
    console.log("embed", embed);
    return (
      <React.Fragment>
        <Header user={user} login={ "/login/play/"+gameId } />
        <div style={{display:"flex",  justifyContent: "center", marginTop:"calc(1vmin)"}}>
          <div>
            <div style={styles.game}>
            <Game db={db} gameId={ gameId } gameInfo={this.state.gameInfo} user={user} highscore={this.state.highscore} landscape={landscape} scores={this.state.scores}
                  tweet={this.state.tweet} scoreDidUpdate={()=>{this.scoreDidUpdate()}}/>
            </div>
            {
              (embed) ? "" : 
              <div style={styles.ranking}>
                <Ranking db={db} gameId={ gameId } user={user} scores={this.state.scores} highscore={this.state.highscore} landscape={landscape} tweet={this.state.tweet} />
              </div>           
            }
          </div>
        </div>
      </React.Fragment>
    ) 
  }
}

export default Play;