import React from 'react';
import Tweet from './Tweet';

class TweetBrag extends React.Component {
    render() {
        const { user, highscore } = this.props;
        const message = (user && highscore) ? "My score is " + highscore + ". Can you beat me?" 
                : "This is a fun game. Please try!";
        return (
            <Tweet text={message} hashtags="TourneyGames" />
        );
    }
}

export default TweetBrag;