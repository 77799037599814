import React from 'react';
import PropTypes from 'prop-types';
import Game from './Game'
import Header from './Header';
import seedrandom from 'seedrandom';
import { Redirect } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
//import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import Fab from '@material-ui/core/Fab';
import * as firebase from "firebase/app";
import "firebase/firestore";

//import * as firebase from "firebase/app";
//import "firebase/auth";
//import "firebase/firestore";

const rnd = seedrandom();
const FieldValue = firebase.firestore.FieldValue

const styles = theme => ({
  root: {
    margin: theme.spacing.unit,
    marginTop:theme.spacing.unit * 2,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "calc(20vmin)",
  },
  button: {
    margin: theme.spacing.unit * 1,
  },
});

class Play extends React.Component {
  seed = Math.floor(rnd() * 1000000)
  state = { emojis:["", "", ""] };

  handleChange(e, index) {
    const value = e.target.value;
    const chars = [...value];
    const char = (chars.length === 0) ? "" : chars[0];
    var emojis = this.state.emojis;
    emojis[index] = char;
    this.setState({emojis:emojis});
    this.remountGameComponent();
  }

  remountGameComponent() {
    // HACK ALERT: Since Game.js is lazy and creates a game object in onMoun(),
    //  we need to unmount and remount to force the recreation of the game object. 
    this.setState({remount:true});
    setTimeout(()=>{
      this.setState({remount:false});
    }, 10)
  }

  shuffle(e) {
    this.seed = Math.floor(rnd() * 1000000);
    this.remountGameComponent();    
  }

  async publish(e, params) {
    console.log("publish", this);
    const { db, user } = this.props;
    params.uid = user.uid;
    params.name = user.displayName;
    // NOTE: This serverTimestamp() is not perfectly safe. A hacker can put a future date to promote his games. 
    // If we want to make it perfectly safe, we need to use the cloud function to set the timestamp. 
    params.timestamp = FieldValue.serverTimestamp();
    const ref = await db.collection("freecells").add(params);
    console.log(ref.id);
    this.setState({newGame:ref.id});
  }

  render() {
    //console.log(this.seed, this.state.emojis, this.state.emojis.length);
    //console.log(this.state.emojis[0], this.state.emojis[1], this.state.emojis[2])
    const { db, user, classes, params:{landscape} } = this.props;
    if (!user) {
      return <Redirect to="/login/target/make" />
    }
    if (this.state.newGame) {
      return <Redirect to={"/play/"+this.state.newGame }/>
    }
    var emojis = this.state.emojis.filter((emoji)=>{return emoji.length>0})
    const params = {
      cmax: emojis.length, xmax:8, ymax:8, seed:this.seed,
      emojis: emojis,
    };
    //console.log(params);
    return (
      <React.Fragment>
        <Header user={user} />
        <div className={classes.root}>
          <div>
          <Typography component="h2" variant="h5" gutterBottom 
          style={{marginBottom:"calc(1vmin)"}}>
            Please specify three emojis to create your own game. 
          </Typography>
          <Typography variant="body1" gutterBottom>
            MacOS: Cntrol + ⌘ + Space. Windows: Windows + Period
          </Typography>
          <div style={{width:"100%"}}>
          {
            this.state.emojis.map((ch, index)=>{
                return (
                  <TextField key={index} label={"emoji "+ (index+1)} value={ch}
                  className={classes.textField} margin="normal" 
                  onChange = {(e)=>this.handleChange(e, index)} />
                );
            })
          }
          </div>
          { (emojis.length < 3 || this.state.remount) ? "" 
          : <React.Fragment>
            <div  style={{width:"100%", display:"flex", justifyContent:"center"}} >
            <Game db={db} gameInfo={params} landscape={landscape} />
            </div>
          <div style={{width:"100%"}}>
          <Fab variant="extended" className={classes.button} onClick={(e)=>this.shuffle(e)} disabled={emojis.length < 3}>
            <ShuffleIcon/>
          </Fab>
          <Fab variant="extended" color="primary" className={classes.button} onClick={(e)=>this.publish(e, params)} disabled={emojis.length < 3}>
            <PublishIcon/>Publish
          </Fab>
          </div>
          </React.Fragment>
          }
          </div>
        </div>
      </React.Fragment>
    ) 
  }
}

Play.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Play);