import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  name: {
  },
  myname: {
    color: theme.palette.primary.main,    
  },
});

class UserName extends React.Component {
  render() {
    const { classes, name, uid, user } = this.props;
    return (
      <span className={(user && user.uid===uid) ? classes.myname : classes.name}>
        {name} 
      </span>
    )
  }
}

UserName.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserName);