import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import UserName from './UserName';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const styles = theme => ({
  root: {
    margin: theme.spacing.unit * 2,
  },
  paper: {
    marginBottom: theme.spacing.unit,
    padding: theme.spacing.unit,
  },
  emojis: {
    marginRight: theme.spacing.unit * 10,
  },
  cell: {
    float:"left",
    margin: "1px 1px 1px 1px",
    width: "calc(5.0vmin)",
    height: "calc(5.0vmin)",
    borderRadius: "calc(1vmin)",
    fontSize: "calc(4.3vmin)",
    textAlign: "center",
    boxShadow: "2px 2px 2px grey",
  },
  button: {
    float: "right",
  },
});

class GameItem extends React.Component {
  state = { redirect:null };

  render() {
    const { classes, game, user } = this.props;

    return (
      <Paper key={game.gameId} className={classes.paper}>
        <div>
          <div>
            {game.emojis.map((emoji, index)=>{
              return <div key={index} className={classes.cell}>{emoji}</div>
            })}
          </div>
          <span>&nbsp;&nbsp;</span>       
          <UserName user={user} uid={game.uid} name={game.name} />
          <Button variant="contained" color="primary" className={classes.button} component={Link} to={"/play/"+game.gameId}>
            Play
          </Button>
          <div style={{clear:"both"}} />
        </div>
      </Paper>
    )
  }
}

GameItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GameItem);