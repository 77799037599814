import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Home from './Home';
import Play from './Play';
import Make from './Make';
import About from './About';
import Login from './Login';
import GameList from './GameList';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import * as firebase from "firebase/app";
import "firebase/firestore";
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from './theme';
import config from './config';
import Decoder from './Decoder';

import FlexTest from './test/FlexTest';
import FuncTest from './test/FuncTest';

firebase.initializeApp(config);
var db = firebase.firestore();

class Main extends React.Component {
    constructor(props) {
      super(props);
      this.state = { user:null, width:0, height:0 };
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => {
              this.setState({user: user});
              if (user) {
                //console.log("user:", user);
                db.collection("users").doc(user.uid).set({
                  name:user.displayName
                }, { merge: true });
              }
            }
        );
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
      
    componentWillUnmount() {
      this.unregisterAuthObserver();
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
  
    render() {
      const params = {
        landscape:(this.state.width * 1.2 > this.state.height),
      }
      return (
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Fragment>
              <Route exact path="/" render={(props) => <Home {...props} params={params} db={db} user={this.state.user}/>} />
              <Route exact path="/make" render={(props) => <Make {...props} params={params} db={db} user={this.state.user}/>} />
              <Route exact path="/list" render={(props) => <GameList {...props} params={params} db={db} user={this.state.user}/>} />
              <Route exact path="/about" render={(props) => <About {...props} params={params} db={db} user={this.state.user}/>} />
              <Route exact path="/embed/:gameId" render={(props) => <Play {...props} params={params} db={db} user={this.state.user}  embed={true}/>} />
              <Route exact path="/play/:gameId" render={(props) => <Play {...props} params={params} db={db} user={this.state.user}/>} />
              <Route exact path="/login" render={(props) => <Login {...props} params={params} db={db} user={this.state.user}/>} />
              <Route exact path="/login/cmd/:encoded" render={(props) => <Login {...props} params={params} db={db} user={this.state.user}/>} />
              <Route exact path="/login/play/:gameId" render={(props) => <Login {...props} params={params} db={db} user={this.state.user}/>} />
              <Route exact path="/login/target/:target" render={(props) => <Login {...props} params={params} db={db} user={this.state.user}/>} />
              <Route exact path="/flex" render={(props) => <FlexTest {...props} params={params} db={db} user={this.state.user}/>} />
              <Route exact path="/func" render={(props) => <FuncTest {...props} params={params} db={db} user={this.state.user}/>} />
              { // We need to mount the Decoder component only after the user info became available.
                (this.state.user) ?
                  <Route exact path="/decode/:encoded" render={(props) => <Decoder {...props} params={params} db={db} user={this.state.user}/>} />
                  : "" 
              }
            </Fragment>
          </Router>
        </MuiThemeProvider>);
    }
}
export default Main;
