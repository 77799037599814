const config = {
    apiKey: "AIzaSyCRJSgPVFYyebc1lmn77k8C9oKDGn14cZk",
    authDomain: "daily-17266.firebaseapp.com",
    databaseURL: "https://daily-17266.firebaseio.com",
    projectId: "daily-17266",
    storageBucket: "daily-17266.appspot.com",
    messagingSenderId: "514539341619",
    appId: "1:514539341619:web:7f21095dc04b6750"
  };

export default config;